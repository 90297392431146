/**
 * @Description:
 * @author Guan LinTao
 * @date 2:23 下午
 * 全局配置
 */
export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    // 初始化全局配置
    async iniGlobalConfig({ commit }) {
      return new Promise(resolve => {
        resolve()
      })
    }
  }
}
