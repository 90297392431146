import { getUserAuth, userInfo, zeusConfig } from '@/api/public'
import { arrayTransferMap, mergeArray } from '@/utils/tools'
import { arrayToMap } from '@/utils/tools'
import { APPCODE } from '@/config/public'
import { message } from 'ant-design-vue'
import oldAxios from '../../utils/oldAxios'
import { GetMenuInfo, GetParent } from '@/utils/tools'

const state = {
  folderList: [],
  userInfo: {},
  userMenu: [],
  userRights: new Map(),
  roles: false,
  appInfo: {},
  baseAppInfo: {},
  menuObj: {}
}

const getters = {
  getMenuIds: (state) => (id) => {
    if (!id) return []
    return GetParent(state.menuObj, id, 'folderId')
  },
  userInfo: state => state.userInfo,
  roles: state => state.roles,
  folderList: state => state.folderList
}

const mutations = {
  userInfo(state, data) {
    state.userInfo = data
  },
  setUserMenu(state, data) {
    state.userMenu = data
  },
  setRoles: (state, data) => {
    state.roles = data
  },
  setAppInfo: (state, data) => {
    state.appInfo = data
  },
  setBaseAppInfo: (state, data) => {
    state.baseAppInfo = data
  },
  setUserRights: (state, data) => {
    state.userRights = arrayTransferMap(data, 'code')
  },
  setStateData(state, { type, data }) {
    state[type] = data
  }
}

const actions = {
  async getFolderData({ commit }) {
    const response = await oldAxios.get('/media/folder/tree')
    if (response.code === '0000') {
      const menuObj = GetMenuInfo([response.data])
      commit('setStateData', { type: 'menuObj', data: menuObj })
      commit('setStateData', { type: 'folderList', data: [response.data] })
      return response.data
    }
  },
  // 获取用户信息
  async getUserInfo({ commit }) {
    const response = await userInfo()
    const baseAppInfo = await zeusConfig({ appCode: 'gaea', module: 'home' })
    const isAuth = !response.data.phoneAuth || response.data.simplePwd
    commit('setBaseAppInfo', arrayToMap(baseAppInfo.data))
    commit('userInfo', {
      ...response.data,
      isAuth
    })
  },
  // 获取用户菜单
  getUserMenu({ commit }) {
    return new Promise((resolve, reject) => {
      getUserAuth({ appCode: APPCODE }).then(res => {
        if (!res.data.authMenus.length && !res.data.menus.length) {
          message.error('配置信息错误, 请联系管理员')
          reject()
          return
        }
        if (!res.data.authMenus.length) {
          message.error('当前账号，无菜单权限。 3秒后返回首页')
          setTimeout(() => {
            zeusConfig({ appCode: 'zeus', module: 'basic' }).then(res => {
              const configMap = arrayToMap(res.data)
              window.location.href = configMap.console
            })
          }, 3000)
          reject()
          return
        }
        const appInfo = res.data.app
        // 合并筛选菜单权限
        const mergeAllMenus = mergeArray(
          res.data.menus,
          res.data.authMenus
        )
        // 当前应用信息
        commit('setAppInfo', appInfo)
        // 已授权用户菜单
        commit('setUserMenu', res.data.authMenus)
        // 菜单权限标识
        commit('setRoles', true)
        // 用户操作权限
        commit('setUserRights', res.data.resources)
        resolve(mergeAllMenus)
      }).catch(err => reject(err))
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
