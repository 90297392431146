import axios from 'axios'
import { Message } from 'element-ui'

const baseURL = '/api'
axios.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.resolve(error.response)
})

const checkStatus = response => {
  if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
    return response.data
  }
  return new Error('service status error')
}

function checkCode (res) {
  if (res.code !== '0000') {
    if (res.code === '102') {
      const { href } = window.location
      window.location.href = '/api/sso/auth/jump?service=' + href
    }
    if (/[\u4e00-\u9fa5]/.test(res.msg) && res.msg.length < 100) {
      Message.error(res.msg)
    } else if (res.msg !== 'service status error') {
      Message.error('系统繁忙')
    }
  }
  return res
}

export default {
  post (url, data) {
    return axios({
      method: 'post',
      baseURL,
      url,
      data: data,
      timeout: 60000
    }).then(
      (response) => {
        return checkStatus(response)
      }
    ).then(
      (res) => {
        return checkCode(res)
      }
    )
  },
  get (url, params) {
    return axios({
      method: 'get',
      url,
      params, // get 请求时带的参数
      baseURL,
      timeout: 60000
    }).then(
      (response) => {
        return checkStatus(response)
      }
    ).then(
      (res) => {
        return checkCode(res)
      }
    )
  }
}
