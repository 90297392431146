/**
 * @Description:
 * @author Guan LinTao
 * @date 1:48 下午
 * 公用全局配置
 */

// 账号设置
export const accountSetting = 'https://console.aihuamei.cn/home'

// 当前路由默认页
export const defaultRouterPath = '/modelCenter'

// 当前应用标识
export const APPCODE = 'media'
