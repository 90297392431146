import Vue from 'vue'
import {
  Dialog, Loading, Message, Tree, Button, Input, Icon, Upload, RadioGroup, Radio, CheckboxGroup, Checkbox, Cascader,
  MessageBox, Form, FormItem, Pagination, Popover
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Tree)
Vue.use(Button)
Vue.use(Input)
Vue.use(Upload)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Cascader)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Popover)
Vue.use(Pagination)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
